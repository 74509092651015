import { Story } from '../../models/Story';
import { simoneApi } from './simone';

const storyListTag = { type: 'Story' as const, id: 'LIST' };

export const storyApi = simoneApi.injectEndpoints({
    endpoints: (builder) => ({
        getStories: builder.query<Story[], void>({
            query: () => '/stories/',
            providesTags: (result) =>
                result ? [...result.map(({ id }) => ({ type: 'Story' as const, id })), storyListTag] : [storyListTag],
            transformResponse: (response: any) => response,
        }),
        getStory: builder.query<Story, string>({
            query: (id) => `/stories/${id}`,
            providesTags: (story: Story | undefined) => (story ? [{ type: 'Story', id: story.id }] : []),
            transformResponse: (response: any) => transformResponseToStory(response),
        }),
        createStory: builder.mutation<Story, null>({
            query: () => ({
                url: '/stories/',
                method: 'POST',
            }),
            invalidatesTags: [storyListTag],
            transformResponse: (response: any) => transformResponseToStory(response),
        }),
        getOrCreateStory: builder.query<Story, void>({
            query: () => ({
                url: '/stories/simone',
                method: 'POST',
            }),
            providesTags: (story: Story | undefined) => (story ? [{ type: 'Story', id: story.id }] : []),
            transformResponse: (response: any) => transformResponseToStory(response),
        }),
        sendMessage: builder.mutation<Story, { id: string; message: string }>({
            query: ({ id, message }) => ({
                url: `/stories/${id}/messages`,
                method: 'POST',
                body: { speaker: 'User', message: message },
            }),
            invalidatesTags: (_, __, arg) => [{ type: 'Story', id: arg.id }],
            transformResponse: (response: any) => transformResponseToStory(response),
        }),
        updateStory: builder.mutation<Story, Story>({
            query: (body) => ({
                url: `/stories/${body.id}`,
                method: 'PATCH',
                body: transformStoryForRequest(body),
            }),
            invalidatesTags: (_, __, arg) => [{ type: 'Story', id: arg.id }],
            transformResponse: (response: any) => transformResponseToStory(response),
        }),
        deleteStory: builder.mutation<{ id: string }, string>({
            query: (id) => ({
                url: `/stories/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_, __, arg) => [{ type: 'Story', id: arg }, storyListTag],
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetStoriesQuery,
    useGetStoryQuery,
    useGetOrCreateStoryQuery,
    useCreateStoryMutation,
    useUpdateStoryMutation,
    useDeleteStoryMutation,
    useSendMessageMutation,
} = storyApi;

const transformResponseToStory = (response: any): Story => response;
const transformStoryForRequest = (story: Partial<Story> & Pick<Story, 'title'>): any => story;
