import React from 'react';

interface HeySimoneLogoProps {
    color?: string;
    className?: string;
}

const HeySimoneLogo: React.FC<HeySimoneLogoProps> = ({ className, color = '#F7F6F5' }) => {
    return (
        <svg className={className} viewBox="0 0 231 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M219.832 16.1195C216.328 16.1195 214.495 20.1626 214.226 25.1221L224.145 24.9065V23.6666C224.145 18.4375 222.635 16.1195 219.832 16.1195ZM230.991 34.502C229.535 39.2459 225.708 42.7499 219.832 42.7499C212.231 42.7499 207.271 36.9279 207.271 29.4347C207.271 20.8634 213.471 15.1492 220.101 15.1492C227.002 15.1492 231.206 21.0251 230.991 26.0924L214.226 26.0385V26.2002C214.226 34.2864 218.053 37.4669 223.39 37.4669C226.84 37.4669 229.212 36.0653 230.667 34.3403L230.991 34.502Z"
                fill={color}
            />
            <path
                d="M187.592 41.8327V41.9405H178.212V41.8327C179.128 39.0295 179.452 36.6037 179.452 31.9137V25.6604C179.452 21.9408 178.481 19.7845 176.918 17.9516V17.8438L186.136 14.8789V20.1619L186.352 20.2158C188.239 17.6282 190.934 15.1484 195.193 15.1484C200.044 15.1484 203.009 18.1134 203.009 22.8033V31.8598C203.009 36.6037 203.225 39.0295 204.303 41.8327V41.9405H194.869V41.8327C195.84 39.0295 196.109 36.3881 196.109 31.9676V23.7198C196.109 21.0244 194.869 18.7603 191.743 18.7603C189.586 18.7603 187.646 19.8923 186.352 21.4556V31.9137C186.352 36.4959 186.567 39.0295 187.592 41.8327Z"
                fill={color}
            />
            <path
                d="M160.837 42.7499C153.074 42.7499 146.659 36.3349 146.713 29.0034C146.767 21.6181 153.128 15.1492 160.837 15.1492C168.546 15.1492 174.961 21.6181 174.961 29.0034C174.961 36.3349 168.6 42.7499 160.837 42.7499ZM160.837 41.7256C165.635 41.7256 167.683 36.0114 167.683 29.0034C167.683 21.9954 165.581 16.2273 160.837 16.2273C156.093 16.2273 154.045 21.9954 154.045 29.0034C154.045 36.0114 156.039 41.7256 160.837 41.7256Z"
                fill={color}
            />
            <path
                d="M112.871 41.8327V41.9405H103.491V41.8327C104.407 39.0295 104.731 36.6037 104.731 31.9137V25.6604C104.731 21.9408 103.761 19.7845 102.197 17.9516V17.8438L111.415 14.8789V20.1619L111.631 20.2158C113.41 17.3587 116.213 15.1484 120.095 15.1484C123.922 15.1484 126.294 17.0352 126.941 20.2158H127.049C128.882 17.3587 131.685 15.1484 135.566 15.1484C140.094 15.1484 142.574 17.8977 142.574 22.3721V31.8598C142.574 36.6037 142.79 39.0295 143.814 41.8327V41.9405H134.434V41.8327C135.404 39.0295 135.674 36.3881 135.674 31.9676V23.2346C135.674 20.8087 134.811 18.7603 132.116 18.7603C130.283 18.7603 128.289 19.6767 127.049 21.6174V31.8598C127.049 36.6037 127.318 39.0295 128.342 41.8327V41.9405H118.963V41.8327C119.933 39.0295 120.202 36.3881 120.202 31.9676V23.2346C120.202 20.8087 119.34 18.7603 116.591 18.7603C114.758 18.7603 112.817 19.6767 111.631 21.6174V31.9137C111.631 36.4959 111.847 39.0295 112.871 41.8327Z"
                fill={color}
            />
            <path
                d="M98.5256 6.52478C98.5256 8.68109 96.7467 10.4061 94.5904 10.4061C92.4341 10.4061 90.709 8.68109 90.709 6.52478C90.709 4.42238 92.4341 2.64343 94.5904 2.64343C96.7467 2.64343 98.5256 4.42238 98.5256 6.52478ZM90.3856 41.9421V41.8343C91.1403 39.0311 91.6794 36.4974 91.6794 31.9153V25.8237C91.6794 22.158 90.709 20.0017 89.0918 18.1688V17.8454L98.5256 14.8805V31.9153C98.5256 36.4435 98.7952 39.0311 99.7655 41.8343V41.9421H90.3856Z"
                fill={color}
            />
            <path
                d="M84.9946 16.1734L85.5876 22.4267L85.2102 22.5345C82.1375 17.8446 79.4421 16.1195 76.8545 16.1195C74.3209 16.1195 72.6497 17.7367 72.6497 19.893C72.6497 22.9658 76.0459 24.0978 79.6038 25.6073C83.3774 27.2245 87.2587 29.3269 87.2587 34.6637C87.2587 40.324 82.5148 42.7499 76.6928 42.7499C73.674 42.7499 70.6552 42.103 68.391 41.2405L67.7441 34.0168L68.1215 33.909C71.1942 39.192 74.1591 41.7795 77.6631 41.7795C80.8976 41.7795 82.1375 39.6771 82.1375 37.6826C82.1375 34.8255 79.6577 33.5317 76.0998 31.9684C72.4341 30.3511 68.1754 28.4104 68.1754 22.9119C68.1754 17.1977 72.9732 15.1492 78.31 15.1492C80.8437 15.1492 83.3774 15.6343 84.9946 16.1734Z"
                fill={color}
            />
            <path
                d="M41.2656 54.0679L42.5594 48.6771C44.3384 48.8389 49.5674 49.0545 52.748 43.2325L53.2331 42.3699L51.3464 23.3944C50.969 19.4053 50.5377 17.9498 48.7049 16.3325V16.2247H55.8746L57.5457 34.4994L61.5888 27.1141C64.985 20.9147 65.578 18.0576 62.3974 16.3325V16.2247H69.2976V16.3325L52.6401 45.6044C48.8666 52.235 45.0392 53.6366 41.2656 54.0679Z"
                fill={color}
            />
            <path
                d="M34.6678 42.7487C30.0317 42.7487 26.959 39.4064 26.959 33.3148C26.959 24.2044 33.967 15.4175 41.8914 15.4175C43.9399 15.4175 47.4978 16.0644 47.4978 19.2449C47.4978 22.4255 43.886 25.4443 33.3201 25.5521C32.8349 27.3311 32.5654 29.2717 32.5654 31.2663C32.5654 37.0884 34.9912 38.7595 37.6866 38.7595C39.9507 38.7595 42.3227 37.5735 44.0477 34.5008L44.3172 34.7703C42.4305 39.9994 38.8187 42.7487 34.6678 42.7487ZM41.3523 16.3878C38.2796 16.3878 35.099 19.9996 33.5357 25.013C41.9453 23.5575 43.293 20.2153 43.293 18.3824C43.293 17.0347 42.6461 16.3878 41.3523 16.3878Z"
                fill={color}
            />
            <path
                d="M15.2559 42.4253L19.2989 24.2584C19.8919 21.5631 19.4607 19.5685 17.4661 19.5685C14.3933 19.5685 11.5901 24.4202 7.7627 33.3688L7.65488 33.9618C6.95408 37.3041 6.79236 39.4604 7.27753 41.8323V41.9401H0V41.8323C1.23988 39.4604 2.04849 36.4416 3.01883 31.8594L7.54707 10.7276C8.24787 7.43925 8.51741 4.52824 6.41501 2.8032V2.69538L14.8246 0L8.35568 29.4336C11.2667 21.9943 15.3637 15.4176 20.3232 15.4176C23.5576 15.4176 25.6061 18.3286 24.4202 23.935L22.2639 34.3392C21.2396 39.137 20.8623 40.9159 23.8272 41.5089V41.6167L15.2559 42.4253Z"
                fill={color}
            />
        </svg>
    );
};

export default HeySimoneLogo;
