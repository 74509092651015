// src/hooks/useAuth.jsx

import { AccessToken, User } from '@/models/User';
import { setCredentials } from '@/redux/features/app/appSlice';
import { createContext, useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from './useLocalStorage';

interface AuthContextType {
    user: User;
    token: AccessToken;
    setUserAndRedirect: (user: User, token: AccessToken) => Promise<void>;
    clearUserAndRedirect: () => void;
}

const AuthContext = createContext<AuthContextType | null>(null);

interface AuthProviderProps {
    children: React.ReactNode;
}

// AuthProvider is a component that provides authentication context to its
// children.
// - It uses useLocalStorage to persist the user data in local storage.
// - It uses useNavigate to navigate to different routes.
// - It uses useMemo to memoize the value of the context.
// - It provides the user, login, and logout functions to its children.
export const AuthProvider: React.FC<AuthProviderProps> = (props) => {
    // eslint-disable-next-line react/prop-types
    const { children } = props;

    const [localStorageUser, setLocalStorageUser] = useLocalStorage('user', null);
    const [localStorageToken, setLocalStorageToken] = useLocalStorage('token', null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // call this function when you want to authenticate the user
    const setUserAndRedirect = async (user: User, token: AccessToken) => {
        dispatch(setCredentials({ user, access_token: token }));
        setLocalStorageUser(user);
        setLocalStorageToken(token);
        navigate('/');
    };

    // call this function to sign out logged in user
    const clearUserAndRedirect = () => {
        dispatch(setCredentials({ user: undefined, access_token: undefined }));
        setLocalStorageUser(null);
        setLocalStorageToken(null);
        navigate('/', { replace: true });
    };

    const value = useMemo(
        () => ({
            user: localStorageUser,
            token: localStorageToken,
            setUserAndRedirect,
            clearUserAndRedirect,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [localStorageUser, localStorageToken],
    );
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};
