import { Button } from '@/components/ui/button';
import { cn } from '@/libs/utils';
import { ArrowLeft } from 'lucide-react';
import React from 'react';
import { AppMenu } from './AppMenu';

interface AppHeaderProps {
    title: string | React.ReactNode;
    hideGlobalMenu?: boolean;
    hideBackButton?: boolean;
    actions?: {
        items: React.ReactNode[];
        replaceExisting?: boolean;
    };
    backgroundColor?: string;
}

export const AppHeader: React.FC<AppHeaderProps> = (props) => {
    const { title, actions, hideGlobalMenu, hideBackButton, backgroundColor } = props;

    const actionItems = [];

    // Custom actions from the caller
    if (actions && actions?.items.length > 0) {
        actionItems.push(...actions.items.map((item, index) => <React.Fragment key={index}>{item}</React.Fragment>));
    }

    // Default navigation and other global actions
    if (!actions?.replaceExisting || !actions) {
        // Back button
        if (!hideBackButton && window.history.length > 1) {
            actionItems.push(
                <Button key="back" variant="ghost" onClick={() => window.history.back()}>
                    <ArrowLeft />
                </Button>,
            );
        }

        // Global menu
        if (!hideGlobalMenu) {
            actionItems.push(<AppMenu key="menu" />);
        }
    }

    const backButton = actionItems.find((item) => item.key === 'back');

    return (
        <div className={cn('flex', 'flex-row', 'w-screen', 'justify-center', 'justify-items-center', backgroundColor)}>
            {backButton && <div className="pt-3">{backButton}</div>}
            {title && typeof title === 'string' ? (
                <h1 className="grow p-3 font-serif text-3xl text-center">{title}</h1>
            ) : (
                title
            )}
            <div className="p-3 flex flex-row flex-nowrap">{actionItems.filter((item) => item.key !== 'back')}</div>
        </div>
    );
};
