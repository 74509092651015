// import { generateUuid } from '@azure/ms-rest-js';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Story } from '../../../models/Story';
import { StoryState } from './StoryState';

const initialState: StoryState = {
    stories: [],
};

export const storySlice = createSlice({
    name: 'story',
    initialState,
    reducers: {
        createStory: (state, action: PayloadAction<Story>) => {
            state.stories.push(action.payload);
        },
        updateStory: (state, action: PayloadAction<Story>) => {
            const index = state.stories.findIndex((story: { id: string }) => story.id === action.payload.id);
            if (index !== -1) {
                const story = action.payload;
                // if (typeof story.interview_state === 'string') {
                //     story.interview_state = JSON.parse(story.interview_state);
                // }
                state.stories[index] = story;
            }
        },
        deleteStory: (state, action: PayloadAction<string>) => {
            state.stories = state.stories.filter((story) => story.id !== action.payload);
        },
        getStory: (state, action: PayloadAction<Story>) => {
            const index = state.stories.findIndex((story) => story.id === action.payload.id);
            const story = action.payload;
            // if (typeof story.interview_state === 'string') {
            //     story.interview_state = JSON.parse(story.interview_state);
            // }
            state.stories[index] = story;
        },
    },
});

export const { createStory, updateStory, deleteStory, getStory } = storySlice.actions;

export default storySlice.reducer;
