import React from 'react';
import { AppView } from '../components/App/AppView';
import { useSiteUtility } from '../libs/useSiteUtility';

export const Settings: React.FC = () => {
    const siteUtility = useSiteUtility();

    siteUtility.setDocumentTitle('Settings');

    return (
        <AppView title="Settings" hideBottomMenu hideGlobalMenu>
            <div className="flex flex-col items-center justify-center h-full">Settings.</div>
        </AppView>
    );
};
