export const getItem = (keyName: string, defaultValue: any = null) => {
    try {
        const value = window.localStorage.getItem(keyName);
        if (value !== null) {
            return JSON.parse(value);
        } else {
            window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
            return defaultValue;
        }
    } catch (err) {
        return defaultValue;
    }
};

export const setItem = (keyName: string, value: any) => {
    try {
        window.localStorage.setItem(keyName, JSON.stringify(value));
    } catch (err) {
        console.log(err);
    }
};

export const removeItem = (keyName: string) => {
    try {
        window.localStorage.removeItem(keyName);
    } catch (err) {
        console.log(err);
    }
};

const localStorageHelper = {
    getItem,
    setItem,
    removeItem,
};

export default localStorageHelper;
