import { Button, buttonVariants } from '@/components/ui/button';
import { cn } from '@/libs/utils';
import { RefreshCw } from 'lucide-react';

interface SuggestionButtonProps {
    suggestion: string;
    idx: number;
    onClick: (suggestion: string) => void;
}

export const SuggestionButton = ({ suggestion, idx, onClick }: SuggestionButtonProps) => {
    if (suggestion === 'Surprise Me') {
        return;
        // return (
        //     <div
        //         className={cn(buttonVariants({ variant: 'outline' }), 'rounded-full')}
        //         onClick={() => onClick(suggestion)}
        //     >
        //         <PartyPopper className="w-full" />
        //     </div>
        // );
    } else if (suggestion === 'More Suggestions') {
        return (
            <div
                className={cn(buttonVariants({ variant: 'outline' }), 'rounded-full')}
                onClick={() => onClick('More suggestions, please!')}
            >
                <RefreshCw className="w-full" />
            </div>
        );
    } else if (suggestion === "I'll describe my own") {
        return;
    } else {
        return (
            <Button variant="outline" onClick={() => onClick(`${idx + 1}.`)}>
                {idx + 1}
            </Button>
        );
    }
};

export default SuggestionButton;
