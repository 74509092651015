import { cn } from '@/libs/utils';
import { ChatMessage } from '@/models/Story';
import Markdown from 'react-markdown';

interface MessageProps {
    message: ChatMessage;
}

const processMessage = (message: ChatMessage): ChatMessage => {
    if (message.speaker !== 'ai') {
        return message;
    }

    // Change the PREFERENCE message to a more readable format.
    if (message.message.startsWith('PREFERENCE: ')) {
        const response = message.message.slice(12);
        return { speaker: message.speaker, message: `Ok! We're going with "${response}"` };
    }

    // Replace all objects inside triple-ticks with a more readable format.
    message.message = message.message.replace(/```([\s\S]*?)```/g, (match, group) => {
        const obj_string = group.trim().replaceAll('{{', '{').replaceAll('}}', '}');
        try {
            const obj = JSON.parse(obj_string); // Try to parse the content as JSON
            function formatObjectToMarkdown(obj: any, depth: number = 0): string {
                let response = '';

                // Indentation based on depth
                const indent = '  '.repeat(depth);

                for (const key in obj) {
                    if (Object.hasOwnProperty.call(obj, key)) {
                        const titleCaseKey = key.charAt(0).toUpperCase() + key.slice(1).replaceAll(/_/g, ' ');

                        if (typeof obj[key] === 'object' && obj[key] !== null) {
                            // Recursively format nested objects
                            response += `${indent}- __${titleCaseKey}__:\n`;
                            response += formatObjectToMarkdown(obj[key], depth + 1);
                        } else {
                            // Format simple key-value pairs
                            response += `${indent}- __${titleCaseKey}__: ${obj[key]}\n`;
                        }
                    }
                }
                return response;
            }

            return formatObjectToMarkdown(obj);
        } catch (error) {
            console.error('Error parsing JSON:', error, obj_string);
            return match; // If JSON parsing fails, return the original match
        }
    });

    // Change the FINAL_PREFERENCES message to a more readable format.
    if (message.message.startsWith('FINAL_PREFERENCES:')) {
        const response = message.message.slice(18);
        return { speaker: message.speaker, message: `Ok! Here are your final preferences:\n${response}` };
    }

    // Remove unneccessary numbered options.
    const lines = message.message.split('\n');
    let newMessage = '';
    for (const line of lines) {
        // If line is a numbered suggestion with "More Suggestions" at the end, remove it.
        if (line.endsWith('More Suggestions')) {
            continue;
        } else if (line.endsWith("I'll describe my own")) {
            continue;
        }
        newMessage += line + '\n';
    }
    return { speaker: message.speaker, message: newMessage };
};

export const Message: React.FC<MessageProps> = ({ message }: MessageProps) => {
    message = processMessage(message);
    const isSimone = message.speaker === 'ai';
    const classes = cn('py-1 px-6', 'simone-message', isSimone ? 'is_simone' : 'is_not_simone');
    return (
        <div className={classes}>
            <Markdown>
                {typeof message.message === 'string' ? message.message : JSON.stringify(message.message)}
            </Markdown>
        </div>
    );
};
