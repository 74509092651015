export const Constants = {
    app: {
        name: 'Hey, Simone!',
        defaultTheme: 'light',
        defaultBrand: 'default',
        autoScrollThreshold: 100,
        maxContentWidth: 900,
        maxInputLength: 4000,
        minChatWidthPercent: 20,
        defaultChatWidthPercent: 33,
    },
    workflow: {
        maxOutlets: 5,
    },
    debug: {
        root: 'hey-simone',
    },
};
