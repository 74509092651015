// import { generateUuid } from '@azure/ms-rest-js';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { Constants } from '../../../Constants';
import { AppStorage } from '../../../libs/AppStorage';
import localStorageHelper from '../../../libs/LocalStorageHelper';
import { AccessToken, User } from '../../../models/User';
import { AppState } from './AppState';

const localStorageKey = {
    devMode: 'devMode',
    chatWidthPercent: 'chatWidthPercent',
    completedFirstRunApp: 'completedFirstRun:app',
    completedFirstRunExperimental: 'completedFirstRun:experimental',
    completedFirstRunWorkflow: 'completedFirstRun:workflow',
};

const initialState: AppState = {
    devMode: localStorage.getItem(localStorageKey.devMode) === 'true',
    errors: [],
    chatWidthPercent:
        AppStorage.getInstance().loadObject<number>(localStorageKey.chatWidthPercent) ??
        Constants.app.defaultChatWidthPercent,
    completedFirstRun: {
        app: AppStorage.getInstance().loadObject<boolean>(localStorageKey.completedFirstRunApp) ?? false,
        experimental:
            AppStorage.getInstance().loadObject<boolean>(localStorageKey.completedFirstRunExperimental) ?? false,
        workflow: AppStorage.getInstance().loadObject<boolean>(localStorageKey.completedFirstRunWorkflow) ?? false,
    },
    user: localStorageHelper.getItem('user', null),
    access_token: localStorageHelper.getItem('token', null),
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setCredentials: (
            state,
            {
                payload: { user, access_token },
            }: PayloadAction<{ user: User | undefined; access_token: AccessToken | undefined }>,
        ) => {
            state.user = user;
            state.access_token = access_token;
            if (access_token) {
                localStorageHelper.setItem('token', access_token);
            } else {
                localStorageHelper.removeItem('token');
            }
        },
        addError: (state: AppState, action: PayloadAction<{ title?: string; message?: string }>) => {
            state.errors?.push({
                id: uuid(),
                title: action.payload.title,
                message: action.payload.message,
            });
        },
        removeError: (state: AppState, action: PayloadAction<string>) => {
            state.errors = state.errors?.filter((error) => error.id !== action.payload);
        },
        clearErrors: (state: AppState) => {
            state.errors = [];
        },
        setCompletedFirstRun: (
            state: AppState,
            action: PayloadAction<{ app?: boolean; experimental?: boolean; workflow?: boolean }>,
        ) => {
            if (action.payload.app !== undefined) {
                AppStorage.getInstance().saveObject(localStorageKey.completedFirstRunApp, action.payload.app);
                if (!state.completedFirstRun) {
                    state.completedFirstRun = {};
                }
                state.completedFirstRun.app = action.payload.app;
            }
            if (action.payload.experimental !== undefined) {
                AppStorage.getInstance().saveObject(
                    localStorageKey.completedFirstRunExperimental,
                    action.payload.experimental,
                );
                if (!state.completedFirstRun) {
                    state.completedFirstRun = {};
                }
                state.completedFirstRun.experimental = action.payload.experimental;
            }
            if (action.payload.workflow !== undefined) {
                AppStorage.getInstance().saveObject(localStorageKey.completedFirstRunWorkflow, action.payload.workflow);
                if (!state.completedFirstRun) {
                    state.completedFirstRun = {};
                }
                state.completedFirstRun.workflow = action.payload.workflow;
            }
        },
    },
});

export const { setCredentials, addError, removeError, clearErrors, setCompletedFirstRun } = appSlice.actions;

export default appSlice.reducer;
