import { Navigate } from 'react-router-dom';
import { useAuth } from '../../libs/AuthProvider';

interface ProtectedRouteProps {
    children: React.ReactNode;
}

// eslint-disable-next-line react/prop-types
export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const userContext = useAuth();
    const user = userContext?.user;
    const token = userContext?.token;
    if (!user || !token) {
        return <Navigate to="/login" />;
    }
    if (new Date(token.expires) < new Date()) {
        // TODO: refresh token.
        return <Navigate to="/login" />;
    }
    return children;
};
