import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { removeError } from '../../redux/features/app/appSlice';

import { Toaster } from '@/components/ui/toaster';
import { useToast } from '@/components/ui/use-toast';

export const ErrorList: React.FC = () => {
    const { errors } = useAppSelector((state: RootState) => state.app);
    const dispatch = useAppDispatch();
    const { toast } = useToast();

    useEffect(() => {
        if (errors && errors.length > 0) {
            for (const error of errors) {
                toast({
                    variant: 'destructive',
                    title: error.title ?? 'Error',
                    description: error.message,
                });
                dispatch(removeError(error.id));
            }
        }
    }, [errors, dispatch, toast]);

    return <Toaster />;
};
