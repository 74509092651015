import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import { addError } from '../features/app/appSlice';

import debug from 'debug';
import { Constants } from '../../Constants';
import { store } from './store';

const log = debug(Constants.debug.root).extend('rtk-query-error-logger');

export const rtkQueryErrorLogger: Middleware = (_api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        let message: string;
        if ('data' in action.payload) {
            action.payload = action.payload.data;
        }

        if (action.payload instanceof Error) {
            message = action.payload.message;
        } else if (typeof action.payload === 'object') {
            message = JSON.stringify(action.payload);
        } else {
            message = action.payload.message;
        }
        store.dispatch(addError({ title: 'Error', message }));
        log('Error in rtkQuery', action.payload);
    }
    return next(action);
};
