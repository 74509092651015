import { Button } from '@/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Skeleton } from '@/components/ui/skeleton';
import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import { ChatMessage, Story } from '@/models/Story';
// import { debug } from 'debug';
import { useEffect, useRef, useState } from 'react';
// import { Constants } from '../../Constants';
import { ArrowRight } from 'lucide-react';
import { useGetOrCreateStoryQuery, useSendMessageMutation } from '../../services/simone/story';
import { Loading } from './Loading';
import { Message } from './Message';
import { Finished } from './Simone/Finished';
import { SuggestionButton } from './Simone/SuggestionButton';

// const log = debug(Constants.debug.root).extend('simone');

// This function extracts buttons to be created from the last message.
const extractSuggestions = (story: Story): string[] => {
    const options: string[] = [];
    if (story.interview_state === null) {
        return options;
    }
    if (story.interview_state.state !== 'QUESTIONING') {
        // 'QUESTIONING'
        return options;
    }
    // Find the last message that has numbered items in it.
    let lastMessage: string | null = null;
    for (const msg of story.interview_state.active_conversation.slice().reverse()) {
        const [speaker, message] = msg;
        if (speaker !== 'ai') {
            continue;
        }
        if (message.includes('1. ')) {
            lastMessage = message;
            break;
        }
    }

    if (lastMessage) {
        const lines = lastMessage.split('\n');
        for (const line of lines) {
            if (line.startsWith('1. ')) {
                options.push(line.slice(3));
            } else if (line.startsWith('2. ')) {
                options.push(line.slice(3));
            } else if (line.startsWith('3. ')) {
                options.push(line.slice(3));
            } else if (line.startsWith('4. ')) {
                options.push(line.slice(3));
            } else if (line.startsWith('5. ')) {
                options.push(line.slice(3));
            } else if (line.startsWith('6. ')) {
                options.push(line.slice(3));
            }
        }
    }
    return options;
};

export const Simone = () => {
    // API calls.
    const { data: story, error: storyError, isLoading, refetch } = useGetOrCreateStoryQuery();
    const [sendMessage, { error, isLoading: messageLoading }] = useSendMessageMutation();

    // Local state.
    const [messages, setMessages] = useState<ChatMessage[]>([]);
    const [suggestions, setSuggestions] = useState<string[]>([]);

    // Form hooks.
    const formSchema = z.object({
        message: z.string().min(1, {
            message: 'Where is the message?',
        }),
    });

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            message: '',
        },
    });

    // Runs on mount and re-fetches the data
    useEffect(() => {
        refetch();
    }, [refetch]);

    // Update appropriate state when story changes.
    useEffect(() => {
        if (!story) {
            return;
        }
        let interview_state = story.interview_state;
        if (interview_state === null) {
            interview_state = {
                state: 'PREPARING_USER',
                full_history: [],
                active_conversation: [],
                preferences: {},
            };
        }

        // Get messages.
        const full = story.interview_state?.full_history || [];
        const active = story.interview_state?.active_conversation || [];
        const messages: ChatMessage[] = [...full, ...active].map((rawMessage) => {
            const speaker = rawMessage[0];
            let message = rawMessage[1];
            return { speaker, message };
        });

        // Get suggestions from last message.
        const options = extractSuggestions(story);
        setSuggestions(options);
        setMessages(messages);
    }, [story]);

    // Scroll to bottom of messages.
    const messagesEndRef = useRef<null | HTMLDivElement>(null);
    useEffect(() => {
        if (messages) messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    // Alternate rendering.
    if (storyError) {
        return <div>Error.</div>;
    }

    if (isLoading) {
        return <Loading />;
    }

    if (!story) {
        return <div>No story found.</div>;
    }

    if (story.interview_state.state === 'DONE') {
        return <Finished story={story} />;
    }

    // Submit message handler.
    const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = async (values: { message: string }) => {
        form.setValue('message', '');
        setMessages([...messages, { speaker: 'user', message: values.message }]);
        await sendMessage({ id: story.id, message: values.message }).unwrap();
    };

    const submitWithMessage = (message: string) => {
        form.setValue('message', message);
        form.handleSubmit(onSubmit)();
    };

    return (
        <div className="flex flex-col max-h-full pt-6">
            <div className="grow flex flex-col gap-4 overflow-y-auto scrollbar-hidden">
                {messages.map((message, index: number) => (
                    <Message key={index} message={message} />
                ))}
                {(isLoading || messageLoading) && (
                    <div className="rounded-lg p-4 snap-end bg-slate-400">
                        <Skeleton className="h-6 w-full" />
                    </div>
                )}
                {error && <div>Message error.</div>}
                <div ref={messagesEndRef} />
            </div>
            <div className="flex flex-col gap-2 my-6">
                {/* TODO: Dynamic buttons. */}
                <div className="flex flex-row gap-2 items-center justify-center">
                    {suggestions.map((suggestion, index) => (
                        <SuggestionButton key={index} suggestion={suggestion} idx={index} onClick={submitWithMessage} />
                    ))}
                </div>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                        <div className="flex flex-row gap-2">
                            <FormField
                                control={form.control}
                                name="message"
                                render={({ field }) => (
                                    <FormItem className="flex-grow">
                                        <FormControl>
                                            <Input
                                                {...field}
                                                autoComplete="false"
                                                disabled={isLoading || messageLoading}
                                                placeholder="Message Simone"
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <Button size="icon" type="submit" disabled={isLoading || messageLoading}>
                                <ArrowRight />
                            </Button>
                        </div>
                    </form>
                </Form>
            </div>
        </div>
    );
};
