import { AppView } from '@/components/App/AppView';
import { useGetStoryQuery } from '@/services/simone';
import React from 'react';
import Markdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import { Loading } from '../components/App/Loading';
import { useSiteUtility } from '../libs/useSiteUtility';

export const Story: React.FC = () => {
    const { storyId } = useParams();

    const { data: story, error: storyError, isLoading } = useGetStoryQuery(storyId ?? '', { skip: !storyId });

    const siteUtility = useSiteUtility();
    siteUtility.setDocumentTitle('Story');

    if (!storyId) {
        return (
            <AppView title="Stories" hideBottomMenu hideGlobalMenu>
                <p>No story ID provided.</p>
            </AppView>
        );
    }

    if (storyError) {
        const errorMessage = JSON.stringify(storyError);
        throw new Error(`Error loading: ${errorMessage}`);
    }

    if (isLoading || !story) {
        return (
            <div className="flex flex-col h-screen">
                <Loading />
            </div>
        );
    }

    return (
        <AppView hideBottomMenu hideGlobalMenu title={story['title']}>
            <div className="reader">
                <Markdown>{story['content']}</Markdown>
            </div>
        </AppView>
    );
};
