import { Login } from '../../models/Login';
import { AccessToken, User } from '../../models/User';
import { simoneApi } from './simone';

export interface UserResponse {
    user: User;
    access_token: AccessToken;
}

export interface TokenResponse {
    access_token: string;
    token_type: string;
    expires_in: number;
    expires_at: string;
    exp: number;
    user_id: string;
    email: string;
    name: string;
    is_email_verified: boolean;
}

export const userApi = simoneApi.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation<UserResponse, Login>({
            query: (body: Login) => ({
                url: '/token',
                method: 'POST',
                headers: {
                    // We use this content type and convert the body into a
                    // URLSearchParams object to conform to the OAuth2 spec.
                    // Usually you would use JSON for the Simone API.
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams(body as any).toString(),
            }),
            // Convert the access token expiration into a real date.
            transformResponse: (response: TokenResponse) => {
                return {
                    user: {
                        id: response.user_id,
                        email: response.email,
                        is_email_verified: response.is_email_verified,
                        name: response.name,
                    } as User,
                    access_token: {
                        token: response.access_token,
                        expires: new Date(response.exp * 1000).toISOString(),
                    },
                };
            },
            invalidatesTags: ['User'],
        }),
        logout: builder.mutation({
            query: (body: string) => ({
                url: '/auth/logout',
                method: 'POST',
                body: body,
            }),
            invalidatesTags: ['User'],
        }),
        getUser: builder.query<User, string>({
            query: (id) => `/users/${id}`,
            providesTags: ['User'],
        }),
    }),
    overrideExisting: false,
});

export const { useLoginMutation, useLogoutMutation, useGetUserQuery } = userApi;
