import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Constants } from '../../../Constants';
import { AppStorage } from '../../../libs/AppStorage';
import { SettingsState } from './SettingsState';

const storageKeys = {
    theme: 'theme',
};

const initialState: SettingsState = {
    theme: AppStorage.getInstance().loadObject<string>(storageKeys.theme) ?? Constants.app.defaultTheme,
};

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setTheme: (state: SettingsState, action: PayloadAction<string>) => {
            AppStorage.getInstance().saveObject(storageKeys.theme, action.payload);
            state.theme = action.payload;
        },
    },
});

export const { setTheme } = settingsSlice.actions;

export default settingsSlice.reducer;
