const StarIcon = () => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.9213 14.7846L26.8327 11.853L17.4483 12.122L22.3758 4.19216L15.3615 10.3849L13.9991 1.1665L12.6372 10.3849L5.62289 4.19216L10.5504 12.122L1.16602 11.853L10.0774 14.7846L2.71409 20.564L11.4393 17.1255L9.54219 26.2498L13.9991 18.0504L18.4565 26.2498L16.5594 17.1255L25.2846 20.564L17.9213 14.7846Z"
                fill="#E47765"
            />
        </svg>
    );
};

export default StarIcon;
