import { useState } from 'react';
import localStorageHelper from './LocalStorageHelper';

export const useLocalStorage = (keyName: string, defaultValue: any) => {
    const [storedValue, setStoredValue] = useState(() => localStorageHelper.getItem(keyName, defaultValue));
    const setValue = (newValue: any) => {
        localStorageHelper.setItem(keyName, newValue);
        setStoredValue(newValue);
    };
    return [storedValue, setValue];
};
