import React from 'react';
import { AppView } from '../components/App/AppView';
import { Simone } from '../components/App/Simone';
import { useSiteUtility } from '../libs/useSiteUtility';

export const SimonePage: React.FC = () => {
    const siteUtility = useSiteUtility();
    siteUtility.setDocumentTitle('Create a new story');

    return (
        <AppView title="Create" hideGlobalMenu hideBottomMenu>
            <Simone />
        </AppView>
    );
};
