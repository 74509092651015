import { Story } from '@/models/Story';
import React from 'react';
import { Link } from 'react-router-dom';
import { AppView } from '../components/App/AppView';
import { Loading } from '../components/App/Loading';
import { useSiteUtility } from '../libs/useSiteUtility';
import { useGetStoriesQuery } from '../services/simone';

export const Stories: React.FC = () => {
    const { data: stories, error: storiesError, isLoading: isLoadingStories } = useGetStoriesQuery();

    const siteUtility = useSiteUtility();
    siteUtility.setDocumentTitle('Dashboard');

    if (storiesError) {
        const errorMessage = JSON.stringify(storiesError);
        return (
            <AppView title="Stories" actions={{ items: [], replaceExisting: true }}>
                <div className="text-red-500">{errorMessage}</div>
            </AppView>
        );
    }

    if (isLoadingStories) {
        return (
            <AppView title="Stories" actions={{ items: [], replaceExisting: true }}>
                <Loading />
            </AppView>
        );
    }

    const notBeingPrepared = (story: Story) => story.preferences && Object.keys(story.preferences).length > 0;

    const defaultBlurb =
        'Lorem ipsum de ui ipsum lorem de li que. Lorem ipsum de ui ipsum lorem de li que. Lorem ipsum de ui MAX 100 characters.';

    const num_images = 4;

    return (
        <AppView title="Stories" hideGlobalMenu hideBackButton noPadding>
            <div className="w-full md:w-8/12 px-3 py-6">
                <div className="flex flex-col gap-6 md:flex-wrap">
                    {stories
                        ?.filter((story: Story) => notBeingPrepared(story))
                        .map((story: Story, idx: number) => {
                            const image_number = (idx % num_images) + 1;
                            return (
                                <Link key={story.id} to={`/story-detail/${story.id}`} className="flex gap-3 md:w-64">
                                    <div className="flex flex-row gap-4">
                                        <div
                                            className="story-image object-cover"
                                            style={{ backgroundImage: `url('/story/${image_number}.png')` }}
                                        />
                                        <div>
                                            <p className="font-semibold font-serif text-2xl pb-2">
                                                {story.title || 'preparing'}
                                            </p>
                                            <p>{story.blurb || defaultBlurb}</p>
                                        </div>
                                    </div>
                                </Link>
                            );
                        })}
                </div>
            </div>
        </AppView>
    );
};
