import { cn } from '@/libs/utils';
import React from 'react';
import { AppBottomMenu } from './AppBottomMenu';
import { AppHeader } from './AppHeader';
import { ErrorList } from './ErrorList';
interface AppViewProps {
    title?: string | React.ReactNode;
    actions?: {
        items: React.ReactNode[];
        replaceExisting?: boolean;
    };
    hideGlobalMenu?: boolean;
    hideBackButton?: boolean;
    fullSizeContent?: boolean;
    children?: React.ReactNode;
    fullScreen?: boolean;
    hideHeader?: boolean;
    hideBottomMenu?: boolean;
    bottomMenuContent?: React.ReactNode;
    noPadding?: boolean;
    appHeaderBackgroundColor?: string;
    style?: React.CSSProperties;
}

export const AppView: React.FC<AppViewProps> = (props) => {
    const {
        title,
        actions,
        children,
        fullScreen,
        noPadding,
        hideHeader,
        hideBottomMenu,
        hideBackButton,
        hideGlobalMenu,
        bottomMenuContent,
        appHeaderBackgroundColor,
        style,
    } = props;

    return (
        <div className="flex flex-col h-full" style={style}>
            {!fullScreen && !hideHeader && (
                <AppHeader
                    title={title ?? 'Hey Simone'}
                    actions={actions}
                    hideBackButton={hideBackButton}
                    hideGlobalMenu={hideGlobalMenu}
                    backgroundColor={appHeaderBackgroundColor}
                />
            )}
            <div className={cn('container', 'grow', 'overflow-y-auto', 'scrollbar-hidden', noPadding ? 'p-0' : '')}>
                {children}
            </div>
            {!fullScreen && !hideBottomMenu && (
                <div id="bottom-menu" className="flex flex-row items-center">
                    {bottomMenuContent ? bottomMenuContent : <AppBottomMenu />}
                </div>
            )}
            <ErrorList />
        </div>
    );
};
