import StarIcon from '@/components/App/StarIcon';
import { Button, buttonVariants } from '@/components/ui/button';
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet';
import { cn } from '@/libs/utils';
import { ArrowUpRight, MessageCircle, Plus } from 'lucide-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { AppMenu } from '../components/App/AppMenu';
import { AppView } from '../components/App/AppView';
import { useSiteUtility } from '../libs/useSiteUtility';

export const Dashboard: React.FC = () => {
    const siteUtility = useSiteUtility();
    siteUtility.setDocumentTitle();

    const appMenuAction = <AppMenu />;

    return (
        <AppView
            title="&nbsp;"
            actions={{ items: [appMenuAction], replaceExisting: true }}
            noPadding
            appHeaderBackgroundColor="bg-green"
        >
            <div className="inter flex flex-col h-full">
                <div className="bg-green p-6 flex flex-col gap-5">
                    <p className="playfair text-4xl text-simone-white">
                        Start a new story with <em>Simone</em>
                    </p>
                    <Link
                        to="/simone"
                        className={cn(buttonVariants({ variant: 'default' }), 'button-looks-like-chat', 'w-44')}
                    >
                        <Plus />
                        Create story
                    </Link>
                </div>
                <div className="bg-light-green text-simone-green p-8 flex flex-col gap-4">
                    <h2 className="font-semibold">Welcome to Hey Simone.</h2>
                    <p>
                        Here, you&apos;re free to explore <em>your</em> desires without constraint, distraction, or
                        judgement. So, settle in, & let your imagination run wild. And, the relationship is
                        benecificial. If you have any feedback, we&apos;d love to hear it.
                    </p>
                    <Button className="button-simone-white-with-border w-44">
                        <MessageCircle />
                        Give feedback
                    </Button>
                </div>
                <Sheet>
                    <SheetTrigger className="flex-auto">
                        <div className="flex flex-col gap-1 bg-grey p-6 h-full">
                            <div className="flex flex-row justify-start items-center text-simone-brown-800 pb-2">
                                <StarIcon />
                                <h2 className="font-semibold grow text-left pl-2 text-simone-brown-800">Tip</h2>
                                <ArrowUpRight className="text-simone-green" />
                            </div>
                            <p className="text-left">
                                Add the Hey Simone app to your phone&apos;s home screen for easy access.
                            </p>
                        </div>
                    </SheetTrigger>
                    <SheetContent className="w-full sm:w-full md:w-full">
                        <SheetHeader>
                            <SheetTitle>Tip</SheetTitle>
                            <SheetDescription className="sr-only">Create a new story with Simone.</SheetDescription>
                        </SheetHeader>
                        <div>Here&apos;s how you install me!</div>
                    </SheetContent>
                </Sheet>
            </div>
        </AppView>
    );
};
