import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import { simoneApi } from '../../services/simone';
import appReducer from '../features/app/appSlice';
import settingsReducer from '../features/settings/settingsSlice';
import storyReducer from '../features/story/storySlice';
import { rtkQueryErrorLogger } from './rtkQueryErrorLogger';

export const store = configureStore({
    reducer: {
        app: appReducer,
        settings: settingsReducer,
        story: storyReducer,
        [simoneApi.reducerPath]: simoneApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore paths where non-serializable values exist
                ignoredPaths: [
                    'app.access_token.expires',
                    'payload.access_token.expires',
                    'simoneApi.mutations.*.data.access_token.expires',
                ],
            },
        }).concat(simoneApi.middleware, rtkQueryErrorLogger),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
