import {
    BaseQueryApi,
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
    createApi,
    fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { RootState } from '../../redux/app/store';

const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    simoneApi,
    extraOptions,
) => {
    // If the access token is present in redux root.app, add it to the headers.
    const prepareHeaders = (headers: Headers, api: Pick<BaseQueryApi, 'getState'>) => {
        const access_token = (api.getState() as RootState).app.access_token;
        if (access_token) {
            headers.set('authorization', `Bearer ${access_token.token}`);
        }
        return headers;
    };

    // Pull the base URL from the environment variables.
    const rawBaseQuery = fetchBaseQuery({ baseUrl: import.meta.env.VITE_HEYSIMONE_DAEMON_URL, prepareHeaders });
    return rawBaseQuery(args, simoneApi, extraOptions);
};

export const simoneApi = createApi({
    reducerPath: 'simoneApi',
    baseQuery: dynamicBaseQuery,
    tagTypes: ['User', 'Story'],
    endpoints: () => ({}),
});
