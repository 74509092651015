import { Button } from '@/components/ui/button';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuShortcut,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useAuth } from '@/libs/AuthProvider';
import { LogIn, LogOut, Menu, Settings } from 'lucide-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const AppMenu: React.FC = () => {
    const navigate = useNavigate();
    const isAuthenticated = localStorage.getItem('token') !== null;
    const logout = useAuth()?.clearUserAndRedirect;

    const handleSignOut = () => {
        logout && logout();
    };

    const handleSignIn = () => {
        navigate('/login');
    };

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="icon">
                    <Menu className="text-simone-white" />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
                <DropdownMenuItem
                    onClick={() => {
                        navigate('/settings');
                    }}
                >
                    <Settings className="mr-2 h-4 w-4" />
                    <span>Settings</span>
                    <DropdownMenuShortcut>⌘S</DropdownMenuShortcut>
                </DropdownMenuItem>
                {isAuthenticated && (
                    <DropdownMenuItem
                        onClick={() => {
                            handleSignOut();
                        }}
                    >
                        <LogOut className="mr-2 h-4 w-4" />
                        <span>Sign out</span>
                        <DropdownMenuShortcut>⌘L</DropdownMenuShortcut>
                    </DropdownMenuItem>
                )}
                {!isAuthenticated && (
                    <DropdownMenuItem
                        onClick={() => {
                            handleSignIn();
                        }}
                    >
                        <LogIn className="mr-2 h-4 w-4" />
                        <span>Sign in</span>
                        <DropdownMenuShortcut>⌘I</DropdownMenuShortcut>
                    </DropdownMenuItem>
                )}
            </DropdownMenuContent>
        </DropdownMenu>
    );
};
