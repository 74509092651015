import { cn } from '@/libs/utils';
import { BookOpen, Home, PlusSquare } from 'lucide-react';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export const AppBottomMenu: React.FC = () => {
    const location = useLocation();
    return (
        <>
            <Link
                to="/"
                className={cn(
                    'h-20',
                    'flex',
                    'flex-col',
                    'gap-1',
                    'flex-grow',
                    'items-center',
                    'justify-center',
                    location.pathname === '/' ? 'selected' : 'unselected',
                )}
            >
                <Home className="w-full" />
                <div>Home</div>
            </Link>
            <Link
                to="/simone"
                className={cn(
                    'h-20',
                    'flex',
                    'flex-col',
                    'gap-1',
                    'flex-grow',
                    'items-center',
                    'justify-center',
                    location.pathname === '/simone' ? 'selected' : 'unselected',
                )}
            >
                <div className="flex flex-col gap-1">
                    <PlusSquare className="w-full" />
                    <div>Create</div>
                </div>
            </Link>
            <Link
                to="/stories"
                className={cn(
                    'h-20',
                    'flex',
                    'flex-col',
                    'gap-1',
                    'flex-grow',
                    'items-center',
                    'justify-center',
                    location.pathname === '/stories' ? 'selected' : 'unselected',
                )}
            >
                <BookOpen className="w-full" />
                <div className="text-sm">Stories</div>
            </Link>
        </>
    );
};
